<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-if="!loading" class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          แก้ไขผู้รับโอนสินค้า
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <h3 class="mb-7">
          <i class="bi bi-person-fill" style="font-size: 20px"></i>
          ผู้รับโอนสินค้า
        </h3>

        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >รหัสผู้รับโอน</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.transferee_code"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="amount" class="required form-label col-sm-10"
            >ชื่อกิจการ / ชื่อบุคคล</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.transferee_name"
              class="form-control"
              name="amount"
              id="amount"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <!-- <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="form-label col-sm-10"
            >เลข 13 หลัก</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.vendor_id_card"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="number"
              maxlength="13"
              class="form-control"
              name="docNumber"
              id="docNumber"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div> -->

        <div class="row d-flex justify-content-center mb-4">
          <label for="input1" class="col-sm-10">ระบุสาขา</label>
          <div class="col-sm-10">
            <div
              class="form-check form-check-inline form-check-custom form-check-solid"
            >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="ไม่มี"
                v-model="form.transferee_branch"
                id="flexRadioChecked"
                checked="checked"
              />
              <label
                class="form-check-label m-4"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >ไม่มี</label
              >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="สนง. ใหญ่"
                v-model="form.transferee_branch"
                id="flexRadioChecked"
                checked="checked"
              />
              <label
                class="form-check-label m-4"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >สนง. ใหญ่</label
              >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="สาขา"
                v-model="form.transferee_branch"
                id="flexRadioChecked2"
              />
              <label
                class="form-check-label"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >สาขา</label
              >
            </div>
          </div>

          <div v-if="form.transferee_branch === 'สาขา'" class="col-sm-10">
            <div class="col-sm-12">
              <input
                v-model="form.transferee_sub_branch_name"
                type="text"
                class="form-control"
                id="input1"
                placeholder="กรณีเป็นสาขา โปรดระบุชื่อสาขา"
                :style="
                  isSubmit
                    ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                    : ''
                "
              />
            </div>
          </div>
        </div>

        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="required form-label col-sm-10"
            >ประเภทกิจการ</label
          >
          <div class="col-sm-10">
            <select
              v-model="form.transferee_company_type"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option>บริษัทมหาชนจำกัด</option>
              <option selected>บริษัทจำกัด</option>
              <option>ห้างหุ้นส่วนจำกัด</option>
              <option>ห้างหุ้นส่วนสามัญ</option>
              <option>ร้านค้า</option>
              <option>บุคคลธรรมดา</option>
              <option>มูลนิธิ</option>
              <option>สมาคม</option>
              <option>กิจการร่วมค้า</option>
              <option>อื่นๆ</option>
            </select>
            <div class="invalid-feedback text-end">กรุณาเลือก</div>
          </div>

          <div
            v-if="form.transferee_company_type === 'อื่นๆ'"
            class="col-sm-10 mt-4"
          >
            <input
              v-model="form.transferee_company_type_desc"
              type="number"
              class="form-control"
              name="amount"
              id="amount"
              placeholder="กรณีอื่นๆ โปรดระบุ"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <!-- -->

        <hr style="color: LightGray" class="mt-10 mb-8" />

        <h3 class="mb-7">
          <i class="bi bi-file-earmark-text" style="font-size: 20px"></i>
          ข้อมูลผู้ขาย / ผู้รับเงิน
        </h3>

        <div class="row d-flex justify-content-center mb-4">
          <label for="discountPerUnit" class="required form-label col-sm-10"
            >เบอร์โทร</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.transferee_tel"
              class="form-control"
              id="discountPerUnit"
              required
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >email</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.transferee_email"
              class="form-control"
              id="discountPerUnit"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="pricePerUnit" class="form-label col-sm-10">ที่อยู่</label>
          <div class="col-sm-10">
            <textarea
              v-model="form.transferee_address"
              class="form-control"
              id="pricePerUnit"
              name="pricePerUnit"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loadingUpdate"
            >
              <div
                v-if="loadingUpdate"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";
import transferApi from "@/api/transfer";

export default {
  setup() {
    document.title = "BELUCA | แก้ไขผู้รับโอนสินค้า";
  },
  components: {
    Loader,
  },
  data: () => ({
    loading: false,
    isSubmit: false,
    loadingUpdate: false,

    form: {
      transferee_code: "",
      transferee_name: "",
      // vendor_id_card: "",
      transferee_branch: "สนง. ใหญ่",
      transferee_sub_branch_name: "",
      transferee_company_type: "บริษัทจำกัด",
      transferee_company_type_desc: "",
      transferee_tel: "",
      transferee_email: "",
      transferee_address: "",
      status: "1",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
  }),

  created() {
    this.getOne();
  },

  methods: {
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await transferApi.transferee.getOne(
          this.$route.query.id
        );
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          ...getOneResponse.data,
        };
        this.loading = false;
      }
    },
    async submit() {
      let updateResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      this.form = {
        ...this.form,
        status: this.form.status === "2" ? "0" : this.form.status,
        companyId: parseInt(localStorage.getItem("companyId")),
        companyBranchId: parseInt(localStorage.getItem("companyBranchId")),
      };
      if (this.isValid) {
        this.loadingUpdate = true;
        updateResponse = await transferApi.transferee.update(
          this.$route.query.id,
          this.form
        );
        if (updateResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/transfer-product/TP1",
              query: {},
            });
          });
          this.loadingUpdate = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "แก้ไขไม่สำเร็จ !",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadingUpdate = false;
        }
      }
    },
    checkFormIsEmpty() {
      !this.form.transferee_name
        ? (this.isValid = false)
        : !this.form.transferee_company_type
        ? (this.isValid = false)
        : !this.form.transferee_tel
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/transfer-product/TP1",
        query: {},
      });
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
